import { graphql, Link } from 'gatsby'
import React from 'react'
import Seo from '../components/seo'
import HeroImage from '../components/hero-image'
import { ReactComponent as Slack } from '../images/slack-icon.svg'
import { ReactComponent as SlackLarge } from '../images/slack-large.svg'
import { ReactComponent as HomeBanner } from '../images/home-devops-landscape.svg'
import { ReactComponent as Cnfc } from '../images/cnfc.svg'
import { ReactComponent as Kubernetes } from '../images/kubernetes_logo.svg'
import { ReactComponent as Okd } from '../images/okd_logo.svg'
import { ArrowRight, Rss } from 'react-feather'
import { StaticImage } from 'gatsby-plugin-image'
import IconBox from '../components/iconbox'
import CommunityMeeting from '../components/community-meeting'

export default function IndexPage({ data }) {
  const posts = data.allMdx.nodes
  const headingStyle =
    'font-headings text-3xl lg:text-5xl text-base-800 text-center mb-2 lg:mb-4'
  const paragraphStyle = 'text-lg lg:text-xl text-base-600 lg:leading-relaxed'

  const LogoBox = ({ ...props }) => {
    const { title, url, logo } = props

    return (
      <a
        target="_blank"
        className="border-2 text-base-800 dark:text-base-900 bg-base-100 hover:border-base-400 hover:bg-base-200 focus:outline-none focus:ring-2 focus:ring-primary-600 rounded-lg w-full border-base-300 p-4 flex flex-col justify-center items-center"
        rel="noopener noreferrer"
        href={url}
        title={title}
      >
        <div className="h-32 lg:min-w-full lg:px-4">{logo}</div>
      </a>
    )
  }

  const CallToAction = ({ title, url, last }) => {
    return (
      <div
        style={{
          marginBottom: last ? '15px' : '0px',
          boxShadow: '10px 15px 0px 0px hsla(var(--tertiary-400), .4)',
        }}
        className="flex w-full rounded-lg"
      >
        <Link
          target={url.indexOf('https://') === 0 ? '_blank' : '_self'}
          className="light flex-shrink-0 w-full inline-flex flex-row space-x-8 text-base-700 hover:text-tertiary-700 items-center justify-between rounded-lg border-2 border-base-100 dark:border-primary-100 px-2 md:tracking-tight lg:tracking-tight lg:px-4 py-6 xl:py-8 bg-gradient-to-br from-tertiary-200 to-success-200 hover:from-tertiary-300 hover:to-success-300 focus:from-tertiary-300 focus:to-success-300 dark:from-base-300 dark:to-tertiary-300 dark:hover:from-base-400 dark:hover:to-tertiary-400 dark:focus:from-base-400 dark:focus:to-tertiary-400 text-xl lg:text-2xl xl:text-3xl font-bold xl:font-semibold focus:outline-none focus:ring-2 focus:ring-primary-600"
          rel="noopener noreferrer"
          to={url}
        >
          {title}
          <span className="flex-shrink-0 rounded-full w-10 h-10 lg:h-14 lg:w-14 flex px-2 py-2 bg-base-100 text-tertiary-700 leading-none">
            <ArrowRight />
          </span>
        </Link>
      </div>
    )
  }

  const Office1 = () => (
    <StaticImage
      src="../images/office-1.jpg"
      alt="A StackRox presentation at an office hour event"
      aspectRatio="1.6"
      className="h-full rounded-lg"
      width="1028"
    />
  )
  const Office2 = () => (
    <StaticImage
      src="../images/office-2.jpg"
      alt="Another StackRox presentation at an office hour event"
      className="h-full rounded-lg"
      width="760"
      height="350"
    />
  )
  const Office3 = () => (
    <StaticImage
      src="../images/office-3.jpg"
      alt="A third StackRox presentation at an office hour event"
      className="h-full rounded-lg"
      width="760"
      height="350"
    />
  )

  const OfficeHoursStacked = () => (
    <StaticImage
      src="../images/office-hours.jpg"
      alt="Office Hours"
      className="h-full rounded-lg"
    />
  )

  const rss = data.site.siteMetadata.socialMedia.filter(
    (media) => media.platform === 'rss',
  )[0].url

  const mosaicImgStyle = 'relative rounded-lg border-2 border-base-100'
  const mosaicImgOverlay =
    'opacity-70 after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:text-base-500 after:text-transparent after:bg-diagonal-lines after:bg-repeat after:opacity-40'

  return (
    <div className="overflow-hidden">
      <Seo />
      <section className="overflow-hidden border-b border-base-300 relative before:absolute before:bg-hero-waves before:pointer-events-none before:hidden lg:before:flex before:text-transparent before:w-full before:h-full before:bg-left-bottom before:bg-repeat-x">
        <div className="page-wrapper flex py-8 md:py-16 lg:pt-24 lg:pb-44">
          <div className="w-full md:w-[55ch] lg:w-[61ch] flex-shrink-0">
            <h1 className="font-headings font-bold text-4xl lg:text-6xl">
              <small className="block mb-2 text-base-600 dark:text-base-500 lg:mb-8 text-xl lg:text-3xl">
                Welcome to
              </small>
              <span className="text-base-800 dark:text-primary-700 flex">
                The StackRox Community
              </span>
            </h1>
            <p className={`${paragraphStyle} my-6 lg:my-8`}>
              Security continues to be a critical need for the broader
              cloud-native ecosystem building and running Kubernetes
              applications. The StackRox community will work toward providing an
              open source Kubernetes-native security solution to protect
              Kubernetes environments.
            </p>
            <div className="flex flex-wrap md:flex-nowrap gap-3 leading-[3.5rem]">
              <IconBox title="Join our Slack" url="/slack/" icon={<Slack />} />
              <IconBox title="Subscribe to our blog" url={rss} icon={<Rss />} />
            </div>
          </div>
          <div className="hidden md:flex w-full relative pointer-events-none">
            <HomeBanner
              alt="StackRox Community banner"
              className="banner text-base-100 dark:text-base-200 absolute md:-top-full md:w-[1610px] md:h-[1040px] md:left-[-350px] lg:left-[-450px] xl:left-[-370px] lg:top-[-55%] lg:w-[2100px] lg:h-[1205px]"
            />
          </div>
        </div>
      </section>

      <section className="z-0 py-12 md:pt-32 md:pb-40 lg:pt-40 relative dark:after:opacity-60 after:z-[-1] after:text-transparent after:bg-wave-pattern after:rotate-180 after:bg-no-repeat after:w-full after:z-[-1] after:absolute after:h-full after:top-0 after:bg-left-top after:scale-x-[-1]">
        <div className="flex flex-col items-center page-wrapper">
          <h2 className={`${headingStyle}`}>Community Meetings</h2>
          <p className={`${paragraphStyle} text-center md:max-w-[50ch]`}>
            Join our monthly community meetings on the second Tuesday of the
            month to discuss the project and learn from the
            engineering&nbsp;team.
          </p>
          <CommunityMeeting />
          <div
            className="absolute w-full hidden md:flex top-0 left-0 h-full pointer-events-none z-[-1]"
            aria-hidden
          >
            <div
              style={{ top: '180px', left: '15vw' }}
              className="bg-tertiary-100 dark:hidden absolute rounded-full h-10 w-10"
            />

            <div
              style={{ top: '32px', right: '15vw' }}
              className="bg-caution-100 dark:hidden absolute rounded-full h-12 w-12"
            />

            <div
              style={{ bottom: '50px', right: '8vw' }}
              className="bg-warning-100 dark:hidden absolute rounded-full h-12 w-12"
            />

            <div
              style={{ bottom: '140px', left: '10vw' }}
              className="bg-alert-100 dark:hidden absolute rounded-full h-10 w-10"
            />
            <div
              style={{ bottom: '100px', left: '55%' }}
              className="bg-primary-100 dark:hidden absolute rounded-full h-14 w-14"
            />
          </div>
        </div>
      </section>

      <section className="get-involved z-0 relative bg-base-700 dark:bg-base-100 py-16 lg:pt-24 lg:pb-40 after:z-[-1] after:text-transparent after:bg-low-poly after:rotate-180 after:bg-no-repeat after:w-full after:absolute after:h-full after:top-0 after:bg-left-bottom after:opacity-60 before:opacity-60 before:transform before:z-[-1] before:bg-low-poly before:bg-no-repeat before:text-transparent before:w-full before:absolute before:h-full before:top-0 before:bg-left-bottom overflow-hidden">
        <h2 className="font-headings text-3xl lg:text-5xl text-base-100 dark:text-base-900 text-center mb-8 md:mb-20">
          Get involved
        </h2>
        <div className="flex flex-col space-y-12 md:flex-row md:space-y-0 md:space-x-8 page-wrapper">
          <div className="md:w-1/2 xl:w-2/5 flex flex-col space-y-12 justify-between">
            <CallToAction url="/slack/" title="Connect with us on Slack" />
            <CallToAction
              url="https://www.youtube.com/playlist?list=PLaR6Rq6Z4IqdB2gA8COYoWKU98T55Eqcg"
              title="Check out our Community Meetings"
            />
            <CallToAction
              last
              url="https://github.com/stackrox/stackrox"
              title="Contribute to StackRox project"
            />
          </div>
          <div className="light md:w-3/5 grid grid-cols-6 gap-3 lg:gap-4 rounded-lg p-3 lg:p-4 bg-tertiary-400 bg-opacity-40">
            <div
              className={`col-span-4 row-span-2 ${mosaicImgStyle} ${mosaicImgOverlay}`}
            >
              <Office1 />
            </div>
            <div
              className={`col-span-2 ${mosaicImgStyle} lg:px-8 bg-tertiary-900`}
            >
              <SlackLarge className="p-2 lg:p-6" />
            </div>
            <div
              className={`col-span-2 ${mosaicImgStyle} lg:px-8 bg-[#1B1F6C]`}
            >
              <OfficeHoursStacked className="p-2 lg:p-6" />
            </div>
            <div className={`col-span-3 ${mosaicImgStyle} ${mosaicImgOverlay}`}>
              <Office2 />
            </div>
            <div className={`col-span-3 ${mosaicImgStyle} ${mosaicImgOverlay}`}>
              <Office3 />
            </div>
          </div>
        </div>
      </section>

      <section className="z-0 py-12 md:pt-24 md:pb-40 min-h-[600px] relative dark:before:opacity-60 before:scale-x-[-1] before:z-[-1] before:bg-wave-pattern before:bg-no-repeat before:text-transparent before:w-full before:absolute before:h-full before:top-0 before:bg-left-top">
        <div className="flex flex-col items-center page-wrapper">
          <h2 className={headingStyle}>
            Supported open source&nbsp;communities
          </h2>
          <p className={`${paragraphStyle} text-center md:max-w-[50ch]`}>
            StackRox contributes to multiple different communities. Join and
            contribute to our neighboring communities and&nbsp;projects!
          </p>
          <div className="space-y-6 my-16 md:space-x-6 md:space-y-0 w-full flex flex-col md:flex-row lg:min-h-[240px]">
            <LogoBox
              title="Cloud Native Computing Foundation"
              url="https://www.cncf.io/"
              logo={<Cnfc />}
            />
            <LogoBox
              title="Kubernetes"
              url="https://kubernetes.io/"
              logo={<Kubernetes />}
            />
            <LogoBox title="okd" url="https://www.okd.io/" logo={<Okd />} />
          </div>
        </div>
        <div
          className="absolute w-full hidden md:flex top-0 left-0 h-full pointer-events-none z-[-1]"
          aria-hidden
        >
          <div
            style={{ top: '80px', left: '20vw' }}
            className="bg-tertiary-100 dark:hidden absolute rounded-full h-10 w-10"
          />

          <div
            style={{ top: '185px', right: '20vw' }}
            className="bg-warning-100 dark:hidden absolute rounded-full h-12 w-12"
          />

          <div
            style={{ bottom: '120px', right: '17vw' }}
            className="bg-caution-100 dark:hidden absolute rounded-full h-12 w-12"
          />

          <div
            style={{ bottom: '-40px', left: '10vw' }}
            className="bg-success-100 dark:hidden absolute rounded-full h-14 w-14"
          />
          <div
            style={{ bottom: '-180px', right: '6vw' }}
            className="bg-intermediate-100 dark:hidden absolute rounded-full h-10 w-10"
          />
        </div>
      </section>

      <section className="relative before:hidden md:before:flex flex-col w-full items-center z-0 before:z-[-1] before:text-transparent before:absolute before:w-full before:h-[100vw] before:top-[-100px] before:bg-gradient-to-b before:from-base-200 before:to-tertiary-400 dark:before:from-base-100 dark:before:to-tertiary-500 before:rounded-full">
        <div className="page-wrapper">
          <h2 className={`${headingStyle}`}>Recent posts</h2>
          <div className="space-y-6 my-12 md:my-16 md:space-x-6 md:space-y-0 w-full flex flex-col md:flex-row">
            {posts.map((post) => (
              <Link
                to={`/blog/${post.slug}`}
                key={post.frontmatter.title}
                className="md:w-1/3 bg-base-100 group rounded-lg border-2 border-base-500 hover:border-primary-600 focus:ring-2 focus:ring-base-500"
              >
                <HeroImage
                  className="p-3"
                  innerClassName="rounded-md before:text-transparent before:absolute before:rounded-md before:pointer-events-none before:pointer-events-none before:z-[1] before:w-full before:h-full before:shadow-image relative"
                  image={post.frontmatter.image}
                />
                <p className="border-t-2 border-base-400 p-3 text-sm lg:text-base font-medium group-hover:text-primary-700">
                  <span className="line-clamp-3">{post.frontmatter.title}</span>
                </p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        kubelinter
        socialMedia {
          platform
          url
        }
      }
    }

    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 2.22
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
