import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import IconBox from './iconbox'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
const { DateTime } = require('luxon')

const CommunityMeeting = () => {
  const DateTimeString = ({ date, time, timezone, timezone2 }) => {
    const dateOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      zone: timezone,
    }
    const timeOptions = {
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    }
    const dt = DateTime.fromISO(date + 'T' + time, {
      zone: timezone,
    }).setLocale('en-US')

    if (!dt.isValid) {
      return <></>
    }

    return (
      <span>
        <span className="md:hidden lg:inline xl:hidden">Date: </span>
        <span>{dt.toLocaleString(dateOptions)} </span>
        <span className="hidden md:inline lg:hidden xl:inline">at</span>
        <span className="block md:inline lg:block xl:inline">
          <span className="md:hidden lg:inline xl:hidden">Time: </span>{' '}
          {dt.toLocaleString(timeOptions)} /{' '}
          {dt.setLocale('en-UK').setZone(timezone2).toLocaleString(timeOptions)}
        </span>
      </span>
    )
  }

  const UTCDateTimeString = (date, time, timezone, length, msFormat) => {
    let d = DateTime.fromISO(date + 'T' + time, {
      zone: timezone,
    })

    if (length) {
      d = d.plus({ hours: 1 })
    }

    return msFormat
      ? d.toUTC().toFormat('y-LL-dd') +
          'T' +
          d.toUTC().toFormat('HH:mm:ss') +
          ';00:00'
      : d.toUTC().toFormat('yLLdd') + 'T' + d.toUTC().toFormat('HHmmss') + 'Z'
  }

  return (
    <StaticQuery
      query={graphql`
        {
          mdx(fileAbsolutePath: { regex: "/index-community-meeting.mdx/" }) {
            frontmatter {
              title
              link
              link_text
              prev_link
              image {
                childImageSharp {
                  gatsbyImageData(width: 1028, formats: [AUTO, WEBP])
                }
              }
              alt
              date
              time
              timezone
              timezone2
            }
            body
          }
        }
      `}
      render={(data) => (
        <div className="office-hours grid mt-10 md:mt-16 md:mb-8 p-4 xl:px-7 lg:py-12 xl:py-16 lg:mb-24 grid-cols-1 gap-4 xl:gap-6 lg:grid-cols-2 grid-flow-row bg-tertiary-100 dark:bg-base-100 w-full rounded-2xl border-2 border-base-400">
          <div className="flex flex-col justify-center">
            <h3 className="inline-flex flex-col font-headings text-xl md:text-2xl break-words lg:text-3xl xl:text-4xl text-base-800 mb-2 space-y-2 lg:mb-3 !leading-tight">
              <>{data.mdx.frontmatter.title}</>
            </h3>
            <p className="text-base text-base-600 font-semibold md:text-lg lg:text-lg xl:text-xl mb-4 xl:mb-6 border-b-2 border-base-400 pb-4 xl:pb-6">
              <DateTimeString
                date={data.mdx.frontmatter.date}
                time={data.mdx.frontmatter.time}
                timezone={data.mdx.frontmatter.timezone}
                timezone2={data.mdx.frontmatter.timezone2}
              />
            </p>
            <div className="font-medium">
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </div>

            <div className="mt-4 flex space-x-2 md:space-x-4">
              <IconBox
                title={data.mdx.frontmatter.link_text}
                url={
                  'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=' +
                  UTCDateTimeString(
                    data.mdx.frontmatter.date,
                    data.mdx.frontmatter.time,
                    data.mdx.frontmatter.timezone,
                    0,
                    false,
                  ) +
                  '%2F' +
                  UTCDateTimeString(
                    data.mdx.frontmatter.date,
                    data.mdx.frontmatter.time,
                    data.mdx.frontmatter.timezone,
                    1,
                    false,
                  ) +
                  '&details=Join%20our%20StackRox%20Community%20Meeting%3A%20' +
                  encodeURIComponent(data.mdx.frontmatter.link) +
                  '&location=' +
                  encodeURIComponent(data.mdx.frontmatter.link) +
                  '&text=StackRox%3A%20' +
                  encodeURIComponent(data.mdx.frontmatter.title)
                }
                icon=""
              />

              <IconBox
                title="Previous Community Meetings"
                url={data.mdx.frontmatter.prev_link}
              />
            </div>
            <p className="mt-2">
              <a
                className="underline hover:text-secondary-600 dark:hover:text-secondary-600 focus:outline-none focus:ring-2 focus:ring-secondary-600 dark:focus:ring-secondary-600"
                href={
                  'https://outlook.live.com/calendar/0/deeplink/compose?body=Join%20our%20StackRox%20Community%20Meeting%3A%20' +
                  encodeURIComponent(data.mdx.frontmatter.link) +
                  '&enddt=' +
                  UTCDateTimeString(
                    data.mdx.frontmatter.date,
                    data.mdx.frontmatter.time,
                    data.mdx.frontmatter.timezone,
                    1,
                    true,
                  ) +
                  '&location=' +
                  encodeURIComponent(data.mdx.frontmatter.link) +
                  '&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=' +
                  UTCDateTimeString(
                    data.mdx.frontmatter.date,
                    data.mdx.frontmatter.time,
                    data.mdx.frontmatter.timezone,
                    0,
                    true,
                  ) +
                  '&subject=StackRox%3A%20' +
                  encodeURIComponent(data.mdx.frontmatter.title)
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Outlook.com
              </a>{' '}
              |{' '}
              <a
                className="underline hover:text-secondary-600 dark:hover:text-secondary-600 focus:outline-none focus:ring-2 focus:ring-secondary-600 dark:focus:ring-secondary-600"
                href={
                  'https://outlook.office.com/calendar/0/deeplink/compose?body=Join%20our%20StackRox%20Community%20Meeting%3A%20' +
                  encodeURIComponent(data.mdx.frontmatter.link) +
                  '&enddt=' +
                  UTCDateTimeString(
                    data.mdx.frontmatter.date,
                    data.mdx.frontmatter.time,
                    data.mdx.frontmatter.timezone,
                    1,
                    true,
                  ) +
                  '&location=' +
                  encodeURIComponent(data.mdx.frontmatter.link) +
                  '&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=' +
                  UTCDateTimeString(
                    data.mdx.frontmatter.date,
                    data.mdx.frontmatter.time,
                    data.mdx.frontmatter.timezone,
                    0,
                    true,
                  ) +
                  '&subject=StackRox%3A%20' +
                  encodeURIComponent(data.mdx.frontmatter.title)
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Office 365
              </a>{' '}
              |{' '}
              <a
                className="underline hover:text-secondary-600 dark:hover:text-secondary-600 focus:outline-none focus:ring-2 focus:ring-secondary-600 dark:focus:ring-secondary-600"
                href="/community-meeting.ics"
                target="_blank"
                rel="noopener noreferrer"
              >
                iCalendar
              </a>
            </p>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.mdx.frontmatter.image)}
              alt=""
              imgClassName="rounded-2xl border-8 border-tertiary-400 dark:border-tertiary-300"
            />
            <p className="sr-only">{data.mdx.frontmatter.alt}</p>
          </div>
        </div>
      )}
    />
  )
}

export default CommunityMeeting
